import React, { useEffect, useRef, useState } from 'react'
import { FeedItemType } from '../types/models'
import { useVideoPlayer } from './VideoPlayerContext'

interface VideoPlayerProps {
  media: NonNullable<FeedItemType['media']>
  id: string
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ media, id }) => {
  const { pauseAllExcept, activePlayerId, isYouTubeApiReady } = useVideoPlayer()
  const playerRef = useRef<YT.Player | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const pendingPlayRef = useRef<boolean>(false)
  const [isThisVideoPlaying, setIsThisVideoPlaying] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const [isPlayerLoaded, setIsPlayerLoaded] = useState(false)
  const [isPlayerReady, setIsPlayerReady] = useState(false)

  const playerId = `${id}-${media.videoId}`

  useEffect(() => {
    if (activePlayerId !== playerId) {
      setIsThisVideoPlaying(false)
      if (isPlayerReady) {
        playerRef.current?.pauseVideo()
      }
    }
  }, [activePlayerId, playerId, isPlayerReady])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isPlayerLoaded && !playerRef.current && isYouTubeApiReady) {
          loadPlayer()
        }
      },
      { 
        threshold: 0,
        rootMargin: '400px 0px' 
      }
    )

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current)
      }
    }
  }, [isYouTubeApiReady, isPlayerLoaded])

  const videoHolderRef = useRef<HTMLDivElement>(null)

  const loadPlayer = () => {
    if (videoHolderRef.current && !playerRef.current) {
      playerRef.current = new window.YT.Player(videoHolderRef.current, {
        videoId: media.videoId,
        playerVars: {
          controls: 1,
          rel: 0,
          showinfo: 0
        },
        events: {
          onReady: () => {
            setIsPlayerLoaded(true)
            setIsPlayerReady(true)
            playerRef.current?.cueVideoById(media.videoId)
            
            // There could be a pending action from before the player was ready.
            // Delay execution slightly to ensure player is fully ready.
            // This is a workaround for a known issue with YouTube IFrames Player API where the player is not fully ready immediately after cueing a video.
            // This solution will not work in Safari, but that's fine as the race condition should be rare.
            setTimeout(() => {
              if (pendingPlayRef.current) {
                console.log('attempting to play')
                setIsThisVideoPlaying(true)
                playerRef.current?.playVideo()
              }
              pendingPlayRef.current = false
            }, 250)
          },
          onStateChange: (event: YT.OnStateChangeEvent) => {
            if (event.data === window.YT.PlayerState.PLAYING) {
              setIsThisVideoPlaying(true);
            } else if (event.data === window.YT.PlayerState.PAUSED) {
              setIsThisVideoPlaying(false);
            }
          },
        },
      })
    }
  }

  useEffect(() => {
    if (isThisVideoPlaying) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsSticky(entry.intersectionRatio < 1)
        },
        { 
          threshold: [1],
          rootMargin: '0px 0px -1px 0px'
        }
      )

      if (containerRef.current) {
        observer.observe(containerRef.current)
      }

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current)
        }
      }
    } else {
      setIsSticky(false)
    }
  }, [isThisVideoPlaying])

  const handleVideoClick = () => {
    if (isThisVideoPlaying) {
      setIsThisVideoPlaying(false)
      if (isPlayerReady) {
        playerRef.current?.pauseVideo()
      }
    } else {
      pauseAllExcept(playerId)
      if (isPlayerReady) {
        setIsThisVideoPlaying(true)
        playerRef.current?.playVideo()
      } else {
        console.log('pending play')
        pendingPlayRef.current = true
      }
    }
  }

  return (
    <div ref={containerRef}>
      <div className="h-52">
        <div 
          className={`flex w-full mb-2.5 ${
            isSticky 
              ? 'fixed top-0 left-0 right-0 z-50 h-52 bg-black' 
              : 'relative h-52'
          }`}
        >
          <div className={`animated-border-box-glow ${isThisVideoPlaying ? 'animated-border-box-active' : ''}`}></div>
          <div className={`animated-border-box p-1 ${isThisVideoPlaying ? 'animated-border-box-active' : ''}`}>
            <div className="relative h-full flex justify-center items-center">
              <img 
                src={`https://i.ytimg.com/vi/${media.videoId}/hqdefault.jpg`} 
                alt={media.title} 
                className={`absolute inset-0 w-full h-full object-cover ${isThisVideoPlaying ? 'hidden' : ''}`}
                loading='lazy'
              />
              <div className={`h-full w-full ${isThisVideoPlaying ? '' : 'hidden'}`}>
                <div 
                  ref={videoHolderRef} 
                  className='h-full w-full'
                />
              </div>
              <span 
                className={`absolute video-play h-full w-full flex justify-center items-center transition-opacity duration-300 ${isPlayerReady ? 'opacity-100' : 'opacity-0'} ${isThisVideoPlaying ? 'pointer-events-none' : ''}`} 
                onClick={handleVideoClick}
              >
                {isPlayerReady && !isThisVideoPlaying && (
                  <svg viewBox="0 0 60 61" aria-hidden="true" className="w-16 h-16"><g><circle cx="30" cy="30.4219" fill="#333333" opacity="0.6" r="30"></circle><path d="M22.2275 17.1971V43.6465L43.0304 30.4218L22.2275 17.1971Z" fill="white"></path></g></svg>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 my-2">
        <p className="text-md text-white leading-5 font-bold">{media.title}</p>
        <span className="text-sm text-white">{media.artist}</span>
      </div>
    </div>
  )
}

export default VideoPlayer
import React from 'react'
import { differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays, differenceInWeeks, differenceInMonths, differenceInYears } from 'date-fns'
import { FeedItemType } from '../types/models'
import VideoPlayer from './VideoPlayer'
import { FaRegHeart, FaRegComment, FaRetweet } from 'react-icons/fa'
import { FiShare2 } from 'react-icons/fi'

interface FeedItemProps {
  feedItem: FeedItemType
}

const formatTimeAgo = (date: Date | number) => {
  const now = new Date()
  const diff = {
    seconds: differenceInSeconds(now, date),
    minutes: differenceInMinutes(now, date),
    hours: differenceInHours(now, date),
    days: differenceInDays(now, date),
    weeks: differenceInWeeks(now, date),
    months: differenceInMonths(now, date),
    years: differenceInYears(now, date)
  }

  if (diff.seconds < 60) return `${diff.seconds}s`
  if (diff.minutes < 60) return `${diff.minutes}m`
  if (diff.hours < 24) return `${diff.hours}h`
  if (diff.days < 7) return `${diff.days}d`
  if (diff.weeks < 4) return `${diff.weeks}w`
  if (diff.months < 12) return `${diff.months}mo`
  return `${diff.years}y`
}

const FeedItem: React.FC<FeedItemProps> = ({ feedItem: { id, author, content, timestamp, media, likesCount, commentsCount } }) => {
  const timeAgo = formatTimeAgo(new Date(timestamp))

  return (
    <div className="w-full pt-4 border-b hover:bg-lighter flex flex-col border-b-1 border-gray-800 feed-item-with-video" data-video-id={media?.videoId}>
      <div className="w-full px-4">
        <div className="flex items-center w-full">
          <div className="flex-none mr-4">
            <img src={author.avatar} alt={author.name} className="h-12 w-12 rounded-full flex-none" />
          </div>
          <p className="font-semibold">{author.name}</p>
          <p className="text-sm ml-2 text-gray-500">{author.username}</p>
          <p className="text-sm ml-2 text-gray-500">·</p>
          <p className="text-sm ml-2 text-gray-500">{timeAgo}</p>
          <i className="fas fa-angle-down text-dark ml-auto"></i>
        </div>
        <p className="my-3 text-sm">
          <span>{content}</span>
        </p>
      </div>
      <div className="w-full">
        {media && (
          <VideoPlayer
            media={media}
            id={`post-${id}`}
          />
        )}
      </div>
      <div className="flex justify-between px-4 pt-2 pb-4">
        <button className="flex items-center text-gray-500 hover:text-blue-500">
          <FaRegHeart className="mr-2" size={18} />
          <span>{likesCount}</span>
        </button>
        <button className="flex items-center text-gray-500 hover:text-blue-500">
          <FaRegComment className="mr-2" size={18} />
          <span>{commentsCount}</span>
        </button>
        <button className="flex items-center text-gray-500 hover:text-blue-500">
          <FaRetweet className="mr-2" size={22} />
        </button>
        <button className="flex items-center text-gray-500 hover:text-blue-500">
          <FiShare2 className="mr-2" size={18} />
        </button>
      </div>
    </div>
  )
}

export default FeedItem
import React from 'react'
import ReactDOM from 'react-dom/client'
import Feed from '../components/Feed'
import './application.css'

document.addEventListener('DOMContentLoaded', () => {
  const feedElement = document.getElementById('feed')
  if (feedElement) {
    ReactDOM.createRoot(feedElement).render(
      <React.StrictMode>
        <Feed />
      </React.StrictMode>
    )
  }
})
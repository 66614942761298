import React, { useEffect, useState, useRef, useCallback } from 'react'
import { z } from 'zod'
import FeedItem from './FeedItem'
import { FeedItemType } from '../types/models'
import { VideoPlayerProvider } from './VideoPlayerContext'

const UserSchema = z.object({
  id: z.number(),
  username: z.string(),
})

const VideoSchema = z.object({
  id: z.number(),
  title: z.string(),
  youtube_id: z.string(),
  duration: z.number().nullable(),
  artist_name_parsed: z.string(),
  song_name_parsed: z.string(),
  post_text: z.string().nullable().optional(),
}).partial();

const FeedItemSchema = z.object({
  id: z.number(),
  likes_count: z.number(),
  comments_count: z.number(),
  youtube_id: z.string().nullable(),
  post_text: z.string().nullable(),
  created_at: z.string(),
  user: UserSchema,
  video: VideoSchema.optional(),
  comments: z.array(z.unknown()).optional(),
});

const FeedResponseSchema = z.array(FeedItemSchema)

const Feed: React.FC = () => {
  const [feedItems, setFeedItems] = useState<FeedItemType[]>([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const observer = useRef<IntersectionObserver | null>(null)
  const penultimateFeedItemRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [hasMore])

  const getVideoDetails = (video: z.infer<typeof VideoSchema> | undefined) => {
    if (video?.artist_name_parsed && video?.song_name_parsed) {
      return {
        title: video.song_name_parsed,
        artist: video.artist_name_parsed
      };
    }
    return {
      title: video?.title || '',
      artist: ''
    };
  };

  const fetchFeedItems = useCallback(async (pageNum: number) => {
    try {
      const response = await fetch(`/api/v1/feed_items?page=${pageNum}`)
      const data = await response.json()
      
      const validatedData = FeedResponseSchema.parse(data)
      
      const transformedData = validatedData.map((item) => {
        const videoDetails = getVideoDetails(item.video);
        return {
          id: item.id,
          author: {
            name: item.user.username,
            username: `@${item.user.username}`,
            avatar: `https://api.dicebear.com/6.x/initials/svg?seed=${item.user.username}`
          },
          content: item.post_text,
          timestamp: item.created_at,
          media: item.youtube_id ? {
            type: 'video' as const,
            videoId: item.youtube_id,
            ...videoDetails
          } : undefined,
          likesCount: item.likes_count,
          commentsCount: item.comments_count
        };
      })
      
      setFeedItems(prev => [...prev, ...transformedData] as FeedItemType[])
      setHasMore(transformedData.length > 0)
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.error('Validation error:', error.issues)
      } else if (error instanceof Error) {
        console.error('Fetch error:', error.message)
      } else {
        console.error('Unknown error:', error)
      }
    }
  }, [])

  useEffect(() => {
    fetchFeedItems(page)
  }, [page, fetchFeedItems])

  return (
    <VideoPlayerProvider>
      <div className="bg-black text-white min-h-screen">
        {feedItems.map((post, index) => (
          <div key={`${post.id}-${index}`} ref={index === feedItems.length - 2 ? penultimateFeedItemRef : null}>
            <FeedItem feedItem={post} />
          </div>
        ))}
        {!hasMore && <p className="text-center py-4">No more posts to load</p>}
      </div>
    </VideoPlayerProvider>
  )
}

export default Feed


import React, { createContext, useState, useContext, useEffect } from 'react';

type VideoPlayerContextType = {
  activePlayerId: string | null;
  pauseAllExcept: (uniqueId: string) => void;
  isYouTubeApiReady: boolean;
};

const VideoPlayerContext = createContext<VideoPlayerContextType>({
  activePlayerId: null,
  pauseAllExcept: () => {},
  isYouTubeApiReady: false,
});

export const useVideoPlayer = () => useContext(VideoPlayerContext);

export const VideoPlayerProvider = ({ children }: { children: React.ReactNode }) => {
  const [activePlayerId, setActivePlayerId] = useState<string | null>(null);
  const [isYouTubeApiReady, setIsYouTubeApiReady] = useState(false);

  useEffect(() => {
    const handleYouTubeReady = () => {
      setIsYouTubeApiReady(true);
    };

    if (window.YT && window.YT.Player) {
      setIsYouTubeApiReady(true);
    } else {
      window.addEventListener('YouTubeIframeAPIReady', handleYouTubeReady);
    }

    return () => {
      window.removeEventListener('YouTubeIframeAPIReady', handleYouTubeReady);
    };
  }, []);

  const pauseAllExcept = (playerId: string) => {
    setActivePlayerId(playerId);
  };

  return (
    <VideoPlayerContext.Provider value={{ activePlayerId, pauseAllExcept, isYouTubeApiReady }}>
      {children}
    </VideoPlayerContext.Provider>
  );
};
